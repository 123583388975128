import React from 'react';
import './ProgramDetails.css';
import discoveryZone from '../../assets/discovery.jpg';

const DiscoveryZone: React.FC = () => {
  return (
    <div className="program-detail">
      <h3>Discovery Zone</h3>
      <p>A teacher-led after school enrichment program offered twice a year: October-January and March-May.</p>
      <div className="centered-content">
        <img src={discoveryZone} alt="Discovery zone" className="program-image" />
      </div>
      <ul>
        <li>Students receive a menu of workshop choices</li>
        <li>Placed in one of their top three choices</li>
        <li>Participate one day a week from 2:30-3:30</li>
      </ul>
      <p>For more information, please contact the school office.</p>
    </div>
  );
};

export default DiscoveryZone;
