import React, { useState } from 'react';
import './Programs.css';
import FDC from './programs/FDC';
import TrailBlazers from './programs/TrailBlazers';
import ChessClub from './programs/ChessClub';
import DiscoveryZone from './programs/DiscoveryZone';

const Programs: React.FC = () => {
  const [activeProgram, setActiveProgram] = useState<string>('overview');
  const [hoveredProgram, setHoveredProgram] = useState<number | null>(null);

  const programs = [
    { icon: 'fas fa-building', title: 'Flatbush Development Corporation (FDC)', additionalInfo: 'A free, city-based program that serves children 5 days a week.' },
    { icon: 'fas fa-hiking', title: 'Trail Blazers', additionalInfo: 'Outdoor and environmental education' },
    { icon: 'fas fa-chess', title: 'Friday Chess Club & Team', additionalInfo: 'Learn and compete in chess' },
    { icon: 'fas fa-microscope', title: 'Discovery Zone', additionalInfo: 'Hands-on science and technology activities' },
  ];

  const renderProgramContent = () => {
    switch (activeProgram) {
      case 'fdc':
        return <FDC />;
      case 'trailblazers':
        return <TrailBlazers />;
      case 'chess':
        return <ChessClub />;
      case 'discovery':
        return <DiscoveryZone />;
      default:
        return (
          <div className="program-overview">
            <h3>After-School Programs Overview</h3>
            <p>PS 217 offers a variety of after-school options to support our diverse student body and meet the needs of our families. These programs include:</p>
            <ul className="program-list">
              {programs.map((program, index) => (
                <li
                  key={index}
                  onMouseEnter={() => setHoveredProgram(index)}
                  onMouseLeave={() => setHoveredProgram(null)}
                >
                  <div className="program-item">
                    <i className={program.icon}></i>
                    <span>{program.title}</span>
                  </div>
                  {hoveredProgram === index && (
                    <div className="dropdown-info">{program.additionalInfo}</div>
                  )}
                </li>
              ))}
            </ul>
            <p>Select a program from the menu above to view more details.</p>
          </div>
        );
    }
  };

  return (
    <div className="programs">
      <h2>After-School Programs at PS 217</h2>
      <nav className="program-submenu">
        <button 
          className={activeProgram === 'overview' ? 'active' : ''} 
          onClick={() => setActiveProgram('overview')}
        >
          Overview
        </button>
        <button 
          className={activeProgram === 'fdc' ? 'active' : ''} 
          onClick={() => setActiveProgram('fdc')}
        >
          FDC
        </button>
        <button 
          className={activeProgram === 'trailblazers' ? 'active' : ''} 
          onClick={() => setActiveProgram('trailblazers')}
        >
          Trail Blazers
        </button>
        <button 
          className={activeProgram === 'chess' ? 'active' : ''} 
          onClick={() => setActiveProgram('chess')}
        >
          Chess Club
        </button>
        <button 
          className={activeProgram === 'discovery' ? 'active' : ''} 
          onClick={() => setActiveProgram('discovery')}
        >
          Discovery Zone
        </button>
      </nav>
      <div className="program-content">
        {activeProgram === 'overview' ? (
          <div className="program-overview">
            <h3>After-School Programs Overview</h3>
            <p>PS 217 offers a variety of after-school options to support our diverse student body and meet the needs of our families. These programs include:</p>
            <ul className="program-list">
              {programs.map((program, index) => (
                <li
                  key={index}
                  onMouseEnter={() => setHoveredProgram(index)}
                  onMouseLeave={() => setHoveredProgram(null)}
                >
                  <div className="program-item">
                    <i className={program.icon}></i>
                    <span>{program.title}</span>
                  </div>
                  {hoveredProgram === index && (
                    <div className="dropdown-info">{program.additionalInfo}</div>
                  )}
                </li>
              ))}
            </ul>
            <p>Select a program from the menu above to view more details.</p>
          </div>
        ) : (
          renderProgramContent()
        )}
      </div>
    </div>
  );
};

export default Programs;