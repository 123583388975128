import React from 'react';
import './ProgramDetails.css';
import trailBlazer from '../../assets/trailblazer.jpg';

const TrailBlazers: React.FC = () => {
  const contactEmail = 'registration@trailblazers.org';
  const phoneNumber = '212-529-5113';

  const handleEmailClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.location.href = `mailto:${contactEmail}`;
  };

  return (
    <div className="program-detail">
      <h3>Trail Blazers</h3>
      <p>A fee-based program that meets on-site at PS 217. Flexible scheduling options available.</p>
      <div className="centered-content">
        <img src={trailBlazer} alt="Trailblazer" className="program-image" />
      </div>
      <ul className="program-features">
        <li>Options for 5 days, 3 days, or 1 day per week</li>
        <li>Pricing tiered based on family income</li>
        <li>Limited scholarships and sibling discounts available</li>
        <li>Focuses on play, challenge, and nature science</li>
      </ul>
      <div className="info-box">
        <h4>From the TRAIL BLAZERS website:</h4>
        <p>We know that being a parent is hard, let Trail Blazers help with your childcare needs. Since 1887, Trail Blazers has supported young people in developing knowledge and values for life, so that they step into the world ready to thrive. Our program has the right balance of play, challenge, and nature science to support learning and growing, and we look forward to serving your family!</p>
        <p>Our four core values – respect for one's self, respect for others, respect for the community, and respect for the environment – guide all aspects of our program, ensuring that your child is growing and learning in the process of having an amazing time.</p>
      </div>
      <p><strong>Registration:</strong> Open for 2024-2025</p>
      <p><strong>Website:</strong> <a href="https://trailblazers.org/ps217/" target="_blank" rel="noopener noreferrer">https://trailblazers.org/ps217/</a></p>
      <p><strong>Contact:</strong> <a href="#" onClick={handleEmailClick} className="hidden-email">Email</a> or Call: <a href={`tel:${phoneNumber}`} className="clickable-phone">{phoneNumber}</a></p>
    </div>
  );
};

export default TrailBlazers;
