import React, { useState } from 'react';
import './Hero.css';
import landingImg from '../assets/landing.jpg';

interface ChannelInfo {
  icon: string;
  title: string;
  url?: string;
  additionalInfo: string;
}

const Hero: React.FC = () => {
  const emailAddress = 'info@ps217pa.org';
  const emailSubject = 'Request Konstella Access Code';
  const emailBody = 'I would like to request access to Konstella';
  const infoEmailSubject = 'Info on communications channels';
  const infoEmailBody = 'I would like access to the communications channels please';

  const [hoveredChannel, setHoveredChannel] = useState<number | null>(null);

  const channels: ChannelInfo[] = [
    {
      icon: "fab fa-facebook",
      title: "PS 217 Brooklyn Families Facebook Group",
      url: "https://www.facebook.com/groups/PS217Families/",
      additionalInfo: "Join our Facebook group to connect with other families and stay updated on school events."
    },
    {
      icon: "fab fa-whatsapp",
      title: "Language-specific WhatsApp Groups",
      additionalInfo: "We have groups for Spanish, Bangla, Urdu, and Russian speakers. Contact us to join."
    },
    {
      icon: "fab fa-instagram",
      title: "Principal Bonilla's PS 217 Instagram Page",
      url: "https://www.instagram.com/ps217k/",
      additionalInfo: "Follow our principal's Instagram for school updates and photos."
    },
    {
      icon: "fas fa-mobile-alt",
      title: "PS 217 School App",
      additionalInfo: "Download our school app for news and essential information in over 30 languages."
    }
  ];

  return (
    <div className="hero">
      <h1>PS 217 Parents Association</h1>
      <div className="centered-content">
        <img src={landingImg} alt="landing image" className="program-image" />
      </div>
      <h3>Welcome to our new website!  We will continue working on building out this website over the 2024–2025 school year, adding more information, resources and ways to get involved.
      </h3>
      <section className="communication">
        <h2>Stay Connected</h2>
        <p>We encourage all parents to join our primary communication platform, the KONSTELLA app. It provides multilingual support and keeps you updated on all Parents Association activities.</p>
        <div className="cta-button">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
              window.location.href = mailtoLink;
            }}
          >
            Request Konstella Access Code
          </a>
        </div>
      </section>
      <section className="additional-channels">
        <h3>Additional Communication Channels</h3>
        <ul>
          {channels.map((channel, index) => (
            <li 
              key={index}
              onMouseEnter={() => setHoveredChannel(index)}
              onMouseLeave={() => setHoveredChannel(null)}
            >
              <i className={channel.icon}></i>
              {channel.url ? (
                <a href={channel.url} target="_blank" rel="noopener noreferrer">{channel.title}</a>
              ) : (
                channel.title
              )}
              {hoveredChannel === index && (
                <div className="dropdown-info">{channel.additionalInfo}</div>
              )}
            </li>
          ))}
        </ul>
      </section>
      <section className="contact">
        <h3>Contact Us</h3>
        <p>For more information or to join our communication channels, please <a 
          href="#" 
          onClick={(e) => {
            e.preventDefault();
            const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(infoEmailSubject)}&body=${encodeURIComponent(infoEmailBody)}`;
            window.location.href = mailtoLink;
          }}
        >contact the Parent Association</a>.</p>
      </section>
    </div>
  );
};

export default Hero;
