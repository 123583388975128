import React from 'react';
import './ProgramDetails.css'; // We'll create this CSS file

const FDC: React.FC = () => {
  const contactEmail = 'ayoung@fdconline.org';
  
  const handleEmailClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.location.href = `mailto:${contactEmail}`;
  };

  return (
    <div className="program-detail">
      <h3>Flatbush Development Corporation (FDC)</h3>
      <p>A free, city-based program that serves children 5 days a week. First come, first served.</p>
      <div className="info-box">
        <h4>From the FDC website:</h4>
        <p>P.S. 217 has children from cultures from around the world including (but not limited to) Haiti, Russia, Dominican Republic, Nigeria, and of course, the United States. These cultures are incorporated into the many activities offered by 217’s after-school program, including art, science, dance, and literacy. Each child is exposed to and celebrates all the different nationalities that comprise the study body.</p>
      </div>
      <p>Lifelong Learning is a part of the PS 217 program. Staff members participate in Professional Development that helps them to create a healthy learning environment for the students.  Positive interactions with educators and students promote social-emotional development and a sense of community that is amplified by civic engagement opportunities.</p>
      <ul className="program-features">
        <li>Incorporates various cultures into activities like art, science, dance, and literacy</li>
        <li>Promotes social-emotional development and civic engagement</li>
        <li>Staff participates in Professional Development for a healthy learning environment</li>
      </ul>
      <p><strong>How to Apply:</strong> All applications must go through the DYDC website in the Fall of each school year.</p>
      <p><strong>Contact:</strong> Mr. Aulandar Young at <a href="#" onClick={handleEmailClick} className="hidden-email">Contact Email</a></p>
      <p><strong>Website:</strong> <a href="http://www.fdconline.org/after-school-ps-217" target="_blank" rel="noopener noreferrer">www.fdconline.org/after-school-ps-217</a></p>
    </div>
  );
};

export default FDC;
