import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faSchool, faEnvelope, faMapMarkerAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Hero from './components/Hero';
import About from './components/About';
import Programs from './components/Programs';
import ContactForm from './components/ContactForm';
import ExecutiveBoard from './components/ExecutiveBoard';
import './App.css';

const App: React.FC = () => {
  const [currentSection, setCurrentSection] = useState('hero');
  const [currentSubsection, setCurrentSubsection] = useState('');

  const handleSectionChange = (section: string, subsection: string = '') => {
    setCurrentSection(section);
    setCurrentSubsection(subsection);
  };

  const handleEmailClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.location.href = 'mailto:info@ps217pa.org';
  };

  return (
    <div className="App">
      <header>
        <nav className="main-nav">
          <ul>
            <li className="menu-item">
              <button onClick={() => handleSectionChange('hero')}>
                <FontAwesomeIcon icon={faHome} />
                <span>Home</span>
              </button>
              <ul className="submenu">
                <li>
                  <button onClick={() => handleSectionChange('hero', 'executiveBoard')}>
                    <FontAwesomeIcon icon={faUsers} />
                    <span>Executive Board</span>
                  </button>
                </li>
              </ul>
            </li>
            <li className="menu-item">
              <button onClick={() => handleSectionChange('about')}>
                <FontAwesomeIcon icon={faInfoCircle} />
                <span>About Us</span>
              </button>
            </li>
            <li className="menu-item">
              <button onClick={() => handleSectionChange('programs')}>
                <FontAwesomeIcon icon={faSchool} />
                <span>After-School Programs</span>
              </button>
            </li>
            <li className="menu-item">
              <button onClick={() => handleSectionChange('contact')}>
                <FontAwesomeIcon icon={faEnvelope} />
                <span>Contact Us</span>
              </button>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        {currentSection === 'hero' && currentSubsection !== 'executiveBoard' && <Hero />}
        {currentSection === 'hero' && currentSubsection === 'executiveBoard' && <ExecutiveBoard />}
        {currentSection === 'about' && <About />}
        {currentSection === 'programs' && <Programs />}
        {currentSection === 'contact' && <ContactForm />}
      </main>
      
      <footer className="main-footer">
        <div className="footer-content">
          <div className="footer-section">
            <h4>Quick Links</h4>
            <ul>
              <li><a href="#" onClick={() => handleSectionChange('hero')}>Home</a></li>
              <li><a href="#" onClick={() => handleSectionChange('about')}>About Us</a></li>
              <li><a href="#" onClick={() => handleSectionChange('programs')}>After-School Programs</a></li>
              <li><a href="#" onClick={() => handleSectionChange('contact')}>Contact Us</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Contact</h4>
            <p className="school-address">PS 217 Colonel David Marcus School</p>
            <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <a 
                href="https://maps.app.goo.gl/BWxYu5Ro3NEds3rRA"
                target="_blank" 
                rel="noopener noreferrer"
              >
                &nbsp; 1100 Newkirk Avenue, Brooklyn, NY 11230
              </a>
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} /> 
              <a href="#" onClick={handleEmailClick}>Reach out to us!</a>
            </p>
          </div>
          <div className="footer-section">
            <h4>Follow Us</h4>
            <div className="social-icons">
              <a href="https://www.facebook.com/groups/PS217Families/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="https://www.instagram.com/ps217k/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 PS 217 Parents Association. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default App;