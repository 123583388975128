import React from 'react';
import './About.css';

const About: React.FC = () => {
  const contactEmail = 'info@ps217pa.org';

  const handleEmailClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.location.href = `mailto:${contactEmail}`;
  };

  return (
    <div className="about">
      <h2>Parents Association (PA) at PS 217</h2>
      <p>The Parents Association (PA) at PS 217 brings parents together to organize and fund amazing programs, activities, and events to improve the school and support students. All parents of students currently attending the school are members of the Parent Association. Parents include parents by birth or adoption, step-parents, legally appointed guardians, foster parents, and persons in parental relation to a child.
      </p>
      <p>We would love to work with you! If you would like to help with fundraising, communications, parent outreach, event planning, or have an idea for a project you would like to run on behalf of the PA, please reach out. <a href="#" onClick={handleEmailClick} className="hidden-email">Contact us</a>
      </p>
    </div>
  );
};

export default About;