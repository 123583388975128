import React from 'react';
import './ExecutiveBoard.css';

const ExecutiveBoard: React.FC = () => {
  return (
    <div className="executive-board">
      <h2>PS 217 Leadership Structure</h2>
      <section className="board-section">
        <h3>Parents Association Executive Board 2024-2025</h3>
        <ul>
          <li><strong>President:</strong> Eric Ost</li>
          <li><strong>Co-Vice Presidents:</strong> ZamZam Murtaza, Kazi Sadia Anowar</li>
          <li><strong>Co-Secretaries:</strong> Anahita Rajabova, Erica Flashman</li>
          <li><strong>Treasurer:</strong> Amy DeFrank</li>
        </ul>
      </section>

      <section className="board-section">
        <h3>School Leadership Team (SLT)</h3>
        <ul>
          <li>Most Hafiza Akhtar</li>
          <li>Lisa Khandhar</li>
          <li>Alyssa Vine</li>
          <li>TBD</li>
        </ul>
      </section>

      <section className="board-section">
        <h3>Title 1 Positions</h3>
        <ul>
          <li><strong>Chair:</strong> Sandra Granillo</li>
          <li><strong>Alternate:</strong> Herminia Arredondo</li>
        </ul>
      </section>
    </div>
  );
};

export default ExecutiveBoard;
