import React from 'react';
import './ProgramDetails.css';
import chessImage from '../../assets/chess-kids.jpg'; // Make sure to add this image to your assets folder
import chessBoard from '../../assets/chessboard.jpg';
import psChess from '../../assets/ps217-chess.png';

const ChessClub: React.FC = () => {
  const contactEmail = 'ps217chess@gmail.com';

  const handleEmailClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.location.href = `mailto:${contactEmail}`;
  };

  return (
    <div className="program-detail">
      <div className="centered-content">
        <h3>
          <span className="chess-icon">♞</span>
          Friday Chess Club & Team
          <span className="chess-icon">♞</span>
        </h3>
        <div className="image-overlay-container">
          <img src={chessImage} alt="Children playing chess" className="program-image base-image" />
          <img src={psChess} alt="Ps217 chess" className="program-image overlay-image" />
        </div>
      </div>
      <p>Run by the Impact Coaching Network, this fee-based after school chess club is for kindergarteners and up.</p>
      <ul className="program-features">
        <li>No previous chess experience required</li>
        <li>Runs 1 day/week on Fridays</li>
        <li>Scholarships and discounts available</li>
        <li>Students attending FDC can participate</li>
      </ul>
      <div className="info-box">
        <h4>From the ICN website:</h4>
        <p>The Impact Coaching Chess Network at PS 217 welcomes beginners to advancing tournament players. Our goal is that all PS 217 players can experience both the joy & significant social/educational benefits of learning and playing chess. Our in-person programming will match players into groups based on skill, interest level & age. This allows coaches to identify and create the right training environment for each player (complete beginner - advanced National competitors).</p>
      </div>
      <div className="centered-content">
        <img src={chessBoard} alt="A chessboard" className="program-image" />
      </div>
      <p><strong>Cost:</strong> $745 per semester</p>
      <ul className="program-features">
        <li>Optional Late pick up (5:00 - 5:30 pm): $83 per semester</li>
        <li>10% sibling discount, use code: sibling</li>
      </ul>
      <p><strong>Website:</strong> <a href="https://impactcoachingnetwork.org/ps217chess" target="_blank" rel="noopener noreferrer">https://impactcoachingnetwork.org/ps217chess</a></p>
      <p><strong>Financial Assistance and scholarships are available: </strong><a href="#" onClick={handleEmailClick} className="hidden-email">Apply here</a></p>
      <p><strong>Contact:</strong> <a href="#" onClick={handleEmailClick} className="hidden-email">Email</a></p>
    </div>
  );
};

export default ChessClub;
